import { getIdToken } from "../auth";
import type {
  Feed,
  FeedAbstract,
  FeedThumbnail,
  FeedUpdate,
  RawFeedData,
  RawFeedDataToEdit,
  TimelineFeed,
} from '../common/feed';
import type { JSONable } from '../common/jsonable';

export async function createFeed(channel_id: string, feed: JSONable<RawFeedData>): Promise<string> {
  const resp = await fetch(`/api/c/${channel_id}/feeds`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getIdToken()}`,
    },
    body: JSON.stringify(feed),
  });

  const json = await resp.json();

  if (json.status !== 201) {
    throw new Error(json.message);
  }

  return json.result;
}

export async function getFeeds(
  channel_id: string,
  limit: number,
  offset: number,
  r18?: boolean,
  channel?: string,
  __fetch?: typeof window.fetch,
  token?: string,
): Promise<{ feeds: FeedAbstract[]; count: number }> {
  const _fetch = __fetch ? __fetch : fetch;

  r18 ??= false;

  const resp = await _fetch(`/api/c/${channel_id}/feeds?limit=${limit}&offset=${offset}&r18=${r18}&cid=${channel}`, {
    headers: channel
      ? {
          Authorization: `Bearer ${token ?? (await getIdToken())}`,
        }
      : {},
  });
  const json = await resp.json();

  return json.result;
}

export async function getFeedThumbnail(channel_id: string, after: Date, before: Date) {
  const token = await getIdToken();

  if (token === null) {
    return null;
  }

  const resp = await fetch(`/api/c/${channel_id}/feeds/scheduled?ge=${after.valueOf()}&lt=${before.valueOf()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { status, result, message } = (await resp.json()) as {
    status: number;
    result: JSONable<FeedThumbnail[]>;
    message: string;
  };

  if (status !== 200) {
    throw new Error(`${status}: ${message}`);
  }

  return result.map(({ published_at, ...it }) => ({
    ...it,
    published_at: new Date(published_at),
  }));
}

export async function getFeed(
  channel_id: string,
  feed_id: string,
  subscriber?: string | null,
  token?: string,
  __fetch?: typeof fetch,
): Promise<FeedAbstract | Feed | null> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/c/${channel_id}/feeds/${feed_id}${subscriber ? `?cid=${subscriber}` : ''}`, {
    headers: {
      Authorization: `Bearer ${token ?? (await getIdToken())}`,
    },
  });
  const json = await resp.json();

  return json.result ?? null;
}

export async function getFeedToEdit(
  channel_id: string,
  feed_id: string,
  token?: string,
  __fetch?: typeof fetch,
): Promise<[number, Omit<RawFeedDataToEdit, 'published_at'> & { published_at: number }]> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/c/${channel_id}/feeds/${feed_id}/owner`, {
    headers: {
      Authorization: `Bearer ${token ?? (await getIdToken())}`,
    },
  });
  const json = await resp.json();

  return [json.status, json.result ?? {}];
}

export async function updateFeed(channel_id: string, feed_id: string, feed: JSONable<FeedUpdate>) {
  const resp = await fetch(`/api/c/${channel_id}/feeds/${feed_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getIdToken()}`,
    },
    body: JSON.stringify(feed),
  });

  const json = await resp.json();

  if (json.status !== 204) {
    throw new Error(json.message);
  }
}

export async function deleteFeed(channel_id: string, feed_id: string) {
  const resp = await fetch(`/api/c/${channel_id}/feeds/${feed_id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${await getIdToken()}`,
    },
  });

  const json = await resp.json();

  if (json.status !== 204) {
    throw new Error(json.message);
  }
}

export async function getTimeline(page: number, subscriber?: string) {
  const limit = 50;
  const offset = (page - 1) * limit;

  const resp = await fetch(
    `/api/user/timeline?limit=${limit}&offset=${offset}${subscriber ? `&cid=${subscriber}` : ''}`,
    {
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      },
    },
  );

  const json = await resp.json();

  return json.result as {
    feeds: JSONable<TimelineFeed>[];
    count: number;
  };
}
